body {
  margin: 0;
  cursor: default;
  background-color: white;
}
@font-face {
  font-family: "P";
  src: url("../fonts/P.ttf") format("truetype");
}
@font-face {
  font-family: "N";
  src: url("../fonts/N.ttf") format("truetype");
}
@font-face {
  font-family: "A";
  src: url("../fonts/A.otf") format("truetype");
}
@font-face {
  font-family: "B";
  src: url("../fonts/B.ttf") format("truetype");
}
