.LinePoint {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
}
.LP {
  width: 4px;
  min-height: 4px;
  background-color: #565661;
  border-radius: 50%;
}
.LinePoint > :nth-child(2) {
  margin-left: 3px;
  margin-right: 3px;
}
