.Loading {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 0.7rem;
  color: #013f47;
}
.LoadingImg {
  width: 100px;
  height: auto;
  margin-top: -100px;
}
/* ################################################################################## */
@media (min-width: 0px) and (max-width: 560px) {
}
