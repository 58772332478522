.CV {
  width: 100%;
  min-height: 100vh;
  background-color: #ecf1fd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: A;
  color: rgb(47, 46, 67);
  z-index: 1;
}
.Title {
  font-family: B;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: -1px;
  z-index: 1;
}
.SubTitle {
  direction: rtl;
  font-size: 1.2rem;
  width: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -1px;
  z-index: 1;
  margin-bottom: 25px;
}
.CVBox,
.CVDetail {
  width: auto;
  min-height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: 20px;
}
.Madrak1,
.Madrak2,
.Madrak3 {
  width: 315px;
  height: 450px;
}
.Span1 {
  width: 315px;
  height: auto;
  direction: rtl;
  display: flex;
  flex-direction: column;
}
.Span2 {
  width: 315px;
  height: auto;
  direction: rtl;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
}
.Span3 {
  width: 315px;
  height: auto;
  direction: rtl;
  display: flex;
  flex-direction: column;
}
.DetailTitle {
  font-family: B;
  font-size: 1.2rem;
}
.DT {
  color: gray;
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 0px) and (max-width: 560px) {
  .CVDetail {
    flex-direction: column;
  }
  .Span2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .Span3 {
    margin-bottom: 20px;
  }
}
