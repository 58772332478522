.ProductLarge {
  width: 100%;
}
.ProductLargeTitle {
  font-family: B;
  color: rgb(47, 46, 67);
  font-size: 1.1rem;
  word-spacing: -3px;
  line-height: 20px;
  margin-top: 5px;
}
.ProductLargeSubTitle {
  font-family: A;
  color: gray;
  font-size: 1rem;
  word-spacing: -3px;
  line-height: 20px;
}
.ProductSmall {
  width: 100%;
}
.ProductSmallTitle {
  font-family: B;
  color: rgb(47, 46, 67);
  font-size: 1.1rem;
  word-spacing: -3px;
  line-height: 20px;
  margin-top: 5px;
}
.ProductSmallSubTitle {
  font-family: A;
  color: gray;
  font-size: 1rem;
  word-spacing: -3px;
  line-height: 20px;
}
