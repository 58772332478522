.Item {
  min-width: 250px;
  max-width: 250px;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  background-color: #f8f8fd;
  border-radius: 5px;
  z-index: 2;
  cursor: pointer;
}
.I1 {
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.I2 {
  width: calc(100% - 40px);
  min-height: 160px;
  direction: rtl;
  font-size: 1.2rem;
  text-align: justify;
  word-spacing: -3px;
  margin-left: 20px;
  line-height: 20px;
}
.I3 {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.Comma {
  width: 35px;
  height: auto;
  margin-left: 15px;
}
.Icons {
  color: white;
  font-size: 1.7rem;
  padding: 7px;
  border-radius: 50%;
  border: 2px solid #201b2d;
  margin-right: 15px;
  margin-left: 8px;
}
.Info {
  display: flex;
  flex-direction: column;
  direction: rtl;
  font-size: 1.2rem;
  word-spacing: -3px;
}
.Title {
  font-weight: bold;
  margin-top: 5px;
}
.SubTitle {
  margin-top: -5px;
  color: #5c5c5e;
}
