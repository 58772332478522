.Jobs {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(235, 235, 236, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: A;
  color: rgb(47, 46, 67);
  z-index: 1;
}
.Title {
  font-family: B;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: -1px;
  z-index: 1;
}
.SubTitle {
  direction: rtl;
  font-size: 1.2rem;
  width: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -1px;
  z-index: 1;
}
.Items {
  width: auto;
  min-height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: 40px;
  margin-bottom: 40px;
}
.Circles {
  width: auto;
  min-height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: 40px;
  margin-bottom: 40px;
}
.Circle {
  width: 9px;
  min-height: 9px;
  background-color: #3f4256;
  border-radius: 50%;
}
.Circles > :nth-child(2) {
  margin-left: 10px;
  margin-right: 10px;
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 0px) and (max-width: 560px) {
  .SubTitle {
    width: 80%;
  }
  .Items {
    flex-direction: column-reverse;
  }
  .Items > :nth-child(2) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .Title {
    margin-top: 15px;
  }
}
@media (min-width: 561px) and (max-width: 700px) {
  .SubTitle {
    width: 50%;
  }
  .Circles {
    margin-top: 370px;
  }
}
@media (min-width: 701px) and (max-width: 850px) {
  .SubTitle {
    width: 40%;
  }
  .Circles {
    margin-top: 370px;
  }
}
