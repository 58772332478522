.Home {
  width: 100%;
  min-height: 100vh;
  background-color: #ecf1fd;
  display: flex;
  flex-direction: column;
  animation: fadeIn 2s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.Header {
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  z-index: 2;
  color: #757890;
}
.Logo {
  width: 200px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  font-family: N;
  font-size: 2.3rem;
  color: #ff023f;
  font-weight: bold;
}
.Menu {
  width: calc(100% - 400px);
  min-height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  font-family: A;
  font-size: 1.3rem;
}
.Stuff {
  width: 200px;
  min-height: 100%;
}
.Contact {
  word-spacing: -3px;
  cursor: pointer;
}
.CV {
  margin-left: 30px;
  margin-right: 0px;
  cursor: pointer;
}
.Portfolio {
  margin-left: 40px;
  margin-right: 20px;
  cursor: pointer;
}
.Service {
  margin-left: 20px;
  margin-right: 40px;
  cursor: pointer;
}
.Main {
  cursor: pointer;
}
.Bodi {
  width: 100%;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: row;
}
.Info {
  width: 50%;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
}
.Human {
  width: 50%;
  min-height: calc(100vh - 80px);
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.Data {
  width: 100%;
  min-height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  z-index: 1;
}
.Social {
  width: 100%;
  min-height: 20%;
  z-index: 1;
}
.DataBox {
  width: 380px;
  min-height: auto;
  direction: rtl;
  display: flex;
  flex-direction: column;
  z-index: 2;
  margin-right: 15px;
  margin-top: 30px;
}
.DB {
  width: 25px;
  min-height: 1px;
  background-color: #3f4256;
}
.FANI {
  position: relative;
  font-family: A;
  font-size: 1.3rem;
  color: #3f4256;
  top: -14px;
  left: -30px;
  word-spacing: -3px;
}
.Parham {
  width: 300px;
  height: auto;
  position: relative;
  top: -60px;
  right: -15px;
}
.Detailes {
  font-family: A;
  font-size: 1.2rem;
  color: #3f4256;
  text-align: justify;
  position: relative;
  top: -50px;
  right: -10px;
}
.Humans {
  width: 500px;
  height: 500px;
  z-index: 1;
}
.Scroll {
  width: 12px;
  height: auto;
  margin-right: 30px;
  margin-bottom: 30px;
  cursor: pointer;
}
.Buttons {
  display: flex;
  flex-direction: row;
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 0px) and (max-width: 560px) {
  .Header {
    min-height: 60px;
    justify-content: space-between;
  }
  .Logo {
    width: 100px;
    font-size: 1.5rem;
  }
  .Menu {
    display: none;
  }
  .Stuff {
    width: 100px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  .Bodi {
    flex-direction: column-reverse;
    min-height: calc(100vh - 60px);
  }
  .Info {
    width: 100%;
    min-height: calc(40vh - 80px);
    align-items: flex-start;
    justify-content: center;
  }
  .Human {
    width: 100%;
    min-height: calc(60vh - 80px);
    align-items: flex-end;
    justify-content: center;
  }
  .Data {
    width: 100%;
    min-height: calc(39vh - 80px);
  }
  .Social {
    width: 100%;
    min-height: calc(25vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Humans {
    width: 80%;
    height: auto;
  }
  .DataBox {
    width: calc(100% - 100px);
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
  }
  .Parham {
    min-width: 220px;
    max-width: 50%;
    top: -50px;
  }
  .Detailes {
    right: 0px;
    top: -40px;
  }
}
@media (min-width: 561px) and (max-width: 768px) {
  .Header {
    min-height: 60px;
  }
  .Logo {
    width: 150px;
    font-size: 1.8rem;
    align-items: center;
  }
  .Menu {
    width: calc(100% - 150px);
  }
  .Stuff {
    display: none;
  }
  .Portfolio {
    margin-left: 30px;
    margin-right: 15px;
  }
  .Service {
    margin-left: 15px;
    margin-right: 30px;
  }
  .Bodi {
    flex-direction: column-reverse;
    min-height: calc(100vh - 60px);
  }
  .Info {
    width: 100%;
    min-height: calc(40vh - 80px);
    align-items: flex-start;
    justify-content: center;
  }
  .Human {
    width: 100%;
    min-height: calc(60vh - 80px);
    align-items: flex-end;
    justify-content: center;
  }
  .Data {
    width: 100%;
    min-height: calc(39vh - 80px);
  }
  .Social {
    width: 100%;
    min-height: calc(25vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Humans {
    width: 220px;
    height: 220px;
  }
  .DataBox {
    width: calc(100% - 100px);
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
  }
  .Parham {
    min-width: 220px;
    max-width: 50%;
    top: -50px;
  }
  .Detailes {
    right: 0px;
    top: -40px;
  }
}
@media (min-width: 768px) and (max-width: 1000px) {
  .DataBox {
    width: 350px;
  }
  .DB {
    width: 25px;
    min-height: 1px;
    background-color: #3f4256;
  }
  .Humans {
    width: 350px;
    height: 350px;
  }
}
