.Portfolio {
  width: 100%;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: A;
  color: rgb(47, 46, 67);
  z-index: 1;
}
.Title {
  font-family: B;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: -1px;
  z-index: 1;
}
.SubTitle {
  direction: rtl;
  font-size: 1.2rem;
  width: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -1px;
  z-index: 1;
}
.Items {
  width: auto;
  min-height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: 20px;
  margin-bottom: 40px;
}
.ItemsMenus {
  width: auto;
  min-height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: 20px;
  margin-bottom: 40px;
}
.Items1,
.Items3 {
  width: 250px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.Items2 {
  width: 250px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  align-items: flex-end;
}
.ItemsMenu {
  width: auto;
  min-height: auto;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.2rem;
  font-family: A;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: -20px;
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 0px) and (max-width: 560px) {
  .SubTitle {
    width: 80%;
  }
  .Items {
    flex-direction: column;
  }
  .Items2 {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media (min-width: 561px) and (max-width: 700px) {
  .SubTitle {
    width: 50%;
  }
  .Items3 {
    margin-top: 840px;
    position: absolute;
  }
}
@media (min-width: 701px) and (max-width: 850px) {
  .SubTitle {
    width: 40%;
  }
  .Items3 {
    margin-top: 840px;
    position: absolute;
  }
}
