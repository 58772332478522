.JobsLine {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  z-index: 1;
  animation: FF 6s ease-in;
}
.JL01 {
  min-height: 100%;
  border-right: 1px solid rgb(235, 235, 236);
}
@keyframes FF {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 0px) and (max-width: 768px) {
  .JobsLine {
    position: absolute;
    width: 100%;
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: row;
    z-index: 1;
  }
}
