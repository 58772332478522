.AUL {
  position: relative;
  cursor: pointer;
}
.AUL:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  top: 25px;
  background-color: rgb(2, 66, 74);
  transform-origin: bottom right;
  transition: transform 0.4s ease-out;
  border-radius: 3px;
}
.AUL:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
@keyframes RTL {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0;
  }
}
@keyframes LTR {
  from {
    margin-left: 0;
  }
  to {
    margin-left: 100%;
  }
}

@keyframes BTU {
  from {
    margin-top: 60px;
  }
  to {
    margin-top: 50px;
  }
}
@keyframes UTB {
  from {
    margin-top: 50px;
  }
  to {
    margin-top: 60px;
  }
}
