.Service {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(255, 255, 255);
}
.Info {
  width: 100%;
  min-height: 55vh;
  display: flex;
  flex-direction: row;
}
.Items {
  width: 100%;
  min-height: 45vh;
  display: flex;
  flex-direction: row;
}
.Vanet {
  width: 50%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.Vanet img {
  position: relative;
  width: 360px;
  height: auto;
  margin-left: 25px;
}
.Data {
  width: 50%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  font-family: A;
  font-size: 1.5rem;
  letter-spacing: -1px;
}
.DataLine {
  width: 25px;
  min-height: 1px;
  background-color: rgba(70, 70, 70);
}
.DataLine span {
  position: absolute;
  color: rgba(70, 70, 70);
  margin-left: -50px;
  margin-top: -15px;
}
.Data1 {
  font-size: 1.2rem;
  width: 60%;
  text-align: right;
  direction: rtl;
  color: rgb(30, 30, 30);
  margin-top: 15px;
  text-align: justify;
}
.DataBotton {
  display: flex;
  flex-direction: row;
}
.Signature {
  width: 100px;
  height: auto;
  margin-top: 25px;
}
.ItemsLeft {
  width: 49%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 1%;
}
.ItemsRight {
  width: 49%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 1%;
}
.I1,
.I2,
.I3,
.I4 {
  width: 50%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
}
.IInfo {
  width: 100%;
  min-height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: A;
  font-size: 1.2rem;
  color: rgba(30, 30, 30);
}
.IInfo > :nth-child(1) {
  font-family: N;
  margin-left: 30px;
}
.Percent {
  width: 100%;
  min-height: 3px;
  background-color: rgba(70, 70, 70, 0.2);
  border-radius: 10px;
  margin-bottom: 20px;
}
.Percent > :nth-child(1) {
  width: 90%;
  min-height: 3px;
  background-color: rgba(70, 70, 70);
  border-radius: 10px;
  margin-left: 10%;
}
.Percent4 {
  width: 100%;
  min-height: 3px;
  border-radius: 10px;
  margin-bottom: 20px;
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 0px) and (max-width: 768px) {
  .Service {
    width: 99%;
    min-height: auto;
  }
  .Info {
    min-height: auto;
    flex-direction: column-reverse;
  }
  .Vanet {
    width: 100%;
    min-height: auto;
    align-items: center;
    justify-content: center;
  }
  .Vanet img {
    width: 80%;
    max-width: 65%;
    height: auto;
    margin-left: 0px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .Data {
    width: 90%;
    min-height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-top: 25px;
  }
  .Data1 {
    width: 80%;
    text-align: justify;
    color: rgb(30, 30, 30);
    margin-top: 15px;
    direction: rtl;
  }
  .Items {
    min-height: auto;
    flex-direction: column-reverse;
  }
  .Items1,
  .Items2 {
    width: 100%;
    min-height: auto;
  }
  .ItemsLeft,
  .ItemsRight {
    width: 100%;
    min-height: auto;
    align-items: center;
    justify-content: center;
    margin-right: 0%;
  }
  .I1,
  .I2,
  .I3,
  .I4 {
    width: 80%;
  }
  .IInfo > :nth-child(1) {
    margin-left: 20px;
  }
  .IInfo {
    font-size: 1rem;
  }
  .I1 {
    margin-top: 25px;
  }
}
@media (min-width: 769px) and (max-width: 900px) {
  .I1,
  .I2,
  .I3,
  .I4 {
    width: 85%;
  }
}
@media (min-width: 901px) and (max-width: 1200px) {
  .I1,
  .I2,
  .I3,
  .I4 {
    width: 75%;
  }
}
