.Contact {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ecf1fd;
}
.Co1 {
  width: 100%;
  min-height: 40vh;
  background-color: white;
  z-index: 5;
}
.Co2 {
  width: 100%;
  min-height: 40vh;
  max-height: 40vh;
  z-index: 2;
}
.Co3 {
  width: 100%;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: P;
  font-size: 0.8rem;
  background-color: white;
  z-index: 5;
}
abbr {
  cursor: pointer;
}
.Co3 span {
  padding-left: 15px;
  padding-right: 15px;
}
.Co4 {
  width: 100%;
  min-height: 80vh;
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Arrow {
  transform: rotate(-180deg);
  margin-top: -30px;
  margin-left: 20px;
  margin-right: 20px;
}
.Dat {
  display: flex;
  flex-direction: column;
  width: 300px;
  min-height: 300px;
}
.Pic {
  display: flex;
  flex-direction: column;
  width: 300px;
  min-height: 300px;
  align-items: center;
  justify-content: center;
  background-image: url(../../assets//Human.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: top;
}
.Dat1 {
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: justify;
  font-family: A;
  font-size: 1.3rem;
  color: #404350;
  direction: rtl;
  word-spacing: -3px;
  margin-top: -10px;
}
.Dat2 {
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.Dat1 > :nth-child(2) > :nth-child(1) {
  font-weight: bold;
}
.Cont {
  width: 100%;
  max-width: 100%;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 30px;
  direction: rtl;
}
.Line {
  width: 30px;
  height: 1px;
  background-color: #73788d;
}
.Tel1 {
  direction: ltr;
  font-family: N;
  font-size: 1rem;
  position: relative;
  top: -12px;
  right: 37px;
}
.Tel2 {
  direction: rtl;
  font-family: N;
  font-size: 1rem;
  position: relative;
  top: -12px;
  right: 37px;
  padding-left: 35px;
}
.AD1,
.AD3,
.AD5 {
  font-family: A;
  font-size: 1.2rem;
  width: 100%;
}
.Social {
  direction: ltr;
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 0px) and (max-width: 560px) {
  .Co4 {
    flex-direction: column;
  }
  .Arrow {
    display: none;
  }
  .Dat {
    width: 100%;
    min-height: 50vh;
  }
  .Pic {
    width: 100%;
    min-height: 30vh;
  }
  .Dat1 {
    width: 100%;
    min-height: 25vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.1rem;
  }
  .Dat1 span {
    padding-left: 28px;
    padding-right: 28px;
  }
  .Dat2 {
    width: 100%;
    min-height: 25vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Cont {
    width: 80%;
    min-height: 20vh;
    margin-top: 5vh;
  }
  .Tel1 {
    font-size: 0.9rem;
  }
  .Tel2 {
    font-size: 0.9rem;
  }
  .AD1,
  .AD3,
  .AD5 {
    font-size: 1.1rem;
  }
}
@media (min-width: 561px) and (max-width: 768px) {
  .Dat {
    width: 200px;
    min-height: 350px;
  }
  .Pic {
    width: 200px;
    min-height: 350px;
  }
  .Dat1 {
    min-height: 175px;
  }
  .Dat2 {
    min-height: 175px;
  }
}
